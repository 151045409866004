import React from "react";
import styles from "./Accordion.module.scss";

const DigitalAccordion = () => {
  return (
    <div className={styles.accordion}>
      <div>
        <h4 className="mb-1">Digital File</h4>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>1 iris</p>
          <p className={styles.price}>799 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>2 irises</p>
          <p className={styles.price}>1199 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>3 irises</p>
          <p className={styles.price}>1499 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>4 irises</p>
          <p className={styles.price}>1999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>5 irises</p>
          <p className={styles.price}>2399 NOK</p>
        </div>
      </div>
    </div>
  );
};

export default DigitalAccordion;
