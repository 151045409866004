import React from "react";
import styles from "./Hamburger.module.scss";

function Hamburger({ active }) {
  return (
    <div className={`${styles.hamburger} ${active ? styles.active : ""}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default Hamburger;
