const translation = {
  home: {
    hero: {
      1: "Every eye is a",
      2: "masterpiece",
      3: "High-Definition Iris Photography in an instant",
      4: "From NOK599,-",
    },
    button: {
      findUs: "Find Us",
      moreAboutConcept: "More about the concept",
      openMap: "View on map",
      book: "Book appointment",
      seeMoreQuestions: "See more questions",
    },
    conceptText: {
      1: {
        title: "1. Photo",
        text: "With our state-of-the-art equipment, the process is quick, simple, and completely comfortable. It's just a regular photo, but the result is extraordinary.",
      },
      2: {
        title: "2. Editing",
        text: "Our experts meticulously optimize your image, eliminating any light reflections and setting the iris against a contrast-rich black background. This takes about 30 minutes.",
      },
      3: {
        title: "3. Print",
        text: "Your personal iris portrait is then brought to life, printed in unparalleled quality on the material and in the size you prefer.",
      },
    },
    findUs: {
      title: "Find Us",
      monday: "Monday 10:00 - 15:00",
      weekdays: "Monday - Friday 10:00 - 18:00",
      thursday: "Thursday 12:00 - 20:00",
      friday: "Friday 12:00 - 18:00",
      saturday: "Saturday 10:00 - 16:00",
      sunday: "Sunday closed",
    },
  },
  FAQ: {
    title: "FAQ",
    title2: "Do you have any questions?",
    faqs: [
      {
        question: "Do I need to book in advance?",
        answer:
          "Nope! We accept walk-ins, so just come by. You can also book an appointment online to ensure you don't have to wait.",
      },
      {
        question: "Do you sell gift cards?",
        answer: "Of course! Swing by the store and we'll arrange it for you.",
      },
      {
        question: "Is it uncomfortable?",
        answer:
          "No! We never touch your eye. It's a regular photographic camera that takes the picture.",
      },
      {
        question: "Does it take a long time?",
        answer:
          "No! The whole process is done in 15 minutes to half an hour. Waiting time rarely exceeds 15 minutes, and we have a cozy lounge.",
      },
      {
        question: "Do I get the product right away?",
        answer:
          "Yes! We print and frame the picture within the hour if you want. For larger products in other materials, you'll receive it in the mail within about 2 weeks.",
      },
    ],
  },
  concept: {
    title: "Concept",
    photo:
      "Using state-of-the-art photography equipment, we capture the uniqueness of each eye.",
    edit: "In the editing phase, we use a special technique to highlight the eye's details and colors without altering its natural appearance.",
    print:
      "With the industry's best equipment, we deliver prints of exceptional quality in the desired size and material.",
    wall: "Decorate your walls with a personal and meaningful piece of art, or give a gift that lasts a lifetime.",
    title1: "Immortalize Your Iris",
    text1:
      "Using state-of-the-art photography and printing equipment, we capture the uniqueness of each eye and immortalize it in the highest quality artwork.",
    title2: "Share it With Loved Ones",
    text2:
      "A meaningful experience for yourself, with family or friends. Bring someone you love and share the experience!",
    title3: "A Gift with Meaning",
    text3:
      "You can also give Instant Iris as a gift in the form of gift cards, which are available for purchase in our store. Give a gift that lasts.",
  },
  bookAppointment: {
    title: "Contact Us",
    dropIn: "Ask us anything!",
    description:
      "At Instant Iris, we value both spontaneity and preparation. Although our waiting time rarely exceeds 15 minutes, we recommend that you contact us to make an appointment. This ensures that you get the best experience without waiting. But if spontaneity is more your style, you are of course always welcome to swing in when it suits you best.",
    tellUs: "Ask us anything, and we'll get back to you as soon as possible.",
    openingHours: {
      title: "Opening Hours",
      monday: "Monday 10:00 PM - 15:00 PM",
      weekdays: "Tuesday - Wednesday 10:00 PM - 6:00 PM",
      thursday: "Thursday 12:00 PM - 8:00 PM",
      friday: "Friday 12:00 - 18:00",
      saturday: "Saturday 10:00 AM - 4:00 PM",
      sunday: "Sunday Closed",
    },
    location: {
      title: "Sandviken",
      address: "Steinkjellergaten 2, 5003 Bergen",
    },
    form: {
      book: "Contact Us",
      name: "Name",
      email: "Email",
      message: "Message",
      exampleMessage: "Which product do you recommend for a family of 5?",
      button: "Submit",
    },
  },
  FAQs: {
    title: "Preparation",
    button1: "Preparation",
    button2: "While there",
    button3: "Delivery",
    section_1: [
      {
        question: "Do I need to book an appointment?",
        answer:
          "No! We accept walk-ins, so feel free to drop by. You can also book online if you want to avoid waiting.",
      },
      {
        question: "Where are you located?",
        answer:
          "We currently only have a location in the pedestrian street in downtown Bergen. The address is Strandgaten 88.",
      },
      {
        question: "How long does it take?",
        answer:
          "The picture is done in a second. It can take up to an hour before your picture is printed and ready, depending on complexity of design. Wait times are rarely over 15 minutes.",
      },
      {
        question: "How much does it cost?",
        answer:
          "It varies greatly depending on the number of people, the size of the picture, and the material you choose. We have products starting at 399 NOK!",
      },
      {
        question: "What payment methods do you accept?",
        answer: "We currently only accept cards!",
      },
      {
        question: "Are there any age restrictions?",
        answer:
          "No! But children should ideally be a few years old, as it requires sitting still with head support while the picture is taken.",
      },
      {
        question: "How many people can be in one picture?",
        answer:
          "Up to 5 people! If there are more than that, you can contact us and we'll find a solution.",
      },
    ],
    section_2: [
      {
        question: "Is it uncomfortable?",
        answer: "No! We never touch your eye. It's just a regular camera.",
      },
      {
        question: "Do I get the product right away?",
        answer:
          "Yes! We print and frame the picture within the hour if you wish. For larger products in other materials, you'll receive it in the mail within about 2 weeks.",
      },
      {
        question: "What about contact lenses?",
        answer:
          "You can wear contact lenses, but we recommend that you take them out before the picture is taken. This is because the lenses can reflect light and affect the quality of the picture.",
      },
      {
        question: "What sizes and materials do you offer?",
        answer: `We offer a variety of products in sizes ranging from 20x20cm to 150cm, in both square, rectangular, and circular shapes. See more about what we offer on the "Concept" page.`,
      },
      {
        question: "What about infection control?",
        answer:
          "We follow all infection control guidelines and disinfect equipment between each customer.",
      },
    ],
    section_3: [
      {
        question: "How long does delivery take?",
        answer:
          "Photo prints are ready within the hour, while larger products take about 2 weeks.",
      },
      {
        question: "The picture has defects or is damaged, what do I do?",
        answer:
          "Contact us and we'll find a solution! If it turns out to be our fault or the supplier's fault, you will of course receive a new product.",
      },
    ],
  },
  Gallery: {
    title: "Gallery",
  },
};

export default translation;
