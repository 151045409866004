import styles from "./Accordion.module.scss";

const AluLineAccordion = () => {
  return (
    <div className={styles.accordion}>
      {/* Square Sizes and Prices */}
      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Line</h4>
        <h5>2 Irises</h5>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>20 x 40cm</p>
          <p className={styles.price}>2599 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>30 x 60cm</p>
          <p className={styles.price}>3449 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between mb-1">
          <p className={styles.value}>40 x 80cm</p>
          <p className={styles.price}>4699 NOK</p>
        </div>
        <h5>3 Irises</h5>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>20 x 60cm</p>
          <p className={styles.price}>3399 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>30 x 90cm</p>
          <p className={styles.price}>5299 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between mb-1">
          <p className={styles.value}>40 x 120cm</p>
          <p className={styles.price}>9699 NOK</p>
        </div>
        <h5>4 Irises</h5>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>20 x 80cm</p>
          <p className={styles.price}>3249 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>30 x 120cm</p>
          <p className={styles.price}>5199 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between mb-1">
          <p className={styles.value}>40 x 160cm</p>
          <p className={styles.price}>12999 NOK</p>
        </div>
        <h5>5 Irises</h5>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>20 x 100cm</p>
          <p className={styles.price}>4899 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>30 x 150cm</p>
          <p className={styles.price}>12799 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>40 x 200cm</p>
          <p className={styles.price}>16499 NOK</p>
        </div>
      </div>
      <div>
        <p className="mb-1">
          Contact us on mail or in the store if you want more than 5 irises on
          this product.
        </p>
      </div>
    </div>
  );
};

export default AluLineAccordion;
