import React, { useState } from "react";
import styles from "./ProductCard.module.scss";

const ProductCard = ({ image, title, description, attribute, children }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div
      className={`${styles.productCard} ${accordionOpen ? styles.open : ""}`}
    >
      <div className={styles.cardContent}>
        <div className={styles.left}>
          <img src={image} alt={title} />
        </div>
        <div className={styles.right}>
          <div>
            <h4>{title}</h4>
            <p>{description}</p>
            <div>
              {attribute.map((attr, index) => (
                <p key={index} className="font-bold">
                  {attr}
                </p>
              ))}
            </div>
          </div>
          {children && (
            <button
              className="cta"
              onClick={() => setAccordionOpen(!accordionOpen)}
            >
              Se størrelser og priser
              <span className="icon">{accordionOpen ? "-" : "+"}</span>
            </button>
          )}
        </div>
      </div>
      {accordionOpen && children}
    </div>
  );
};

export default ProductCard;
