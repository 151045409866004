import React from "react";
import styles from "./Home.module.scss";
import bg from "../../assets/bgblue.webp";
import arrow from "../../assets/arrow.png";
import Reviews from "../../components/Review";
import Concept from "../../components/ConceptSection";
import FindUs from "../../components/FindUs";
import Hero from "../../components/Hero";
import FAQSection from "../../components/FAQSection";
import SEO from "../../utils/SEO";

function Home() {
  return (
    <>
      <SEO
        title="Instant Iris"
        description="Instant Iris i Bergen - Spesialister på fotografering av iris. Eviggjør din iris på ønsket materiale og størrelse. Hvert øye er et mesterverk."
      />
      <main>
        <Hero styles={styles} bg={bg} arrow={arrow} />
        <Reviews />
        <Concept />
        <FindUs />
        <FAQSection />
      </main>
    </>
  );
}

export default Home;
