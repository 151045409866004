/* global dataLayer */

import React, { useState, useEffect } from "react";
import styles from "./CookieBanner.module.scss";

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsentGiven");
    if (!consentGiven) {
      const timer = setTimeout(() => {
        setShowBanner(true);
      }, 7000); // 10 seconds delay

      // Cleanup function to clear the timeout if the component is unmounted before the timeout completes
      return () => clearTimeout(timer);
    }
  }, []);

  const initializeAnalytics = () => {
    // Create the Google Analytics script element
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-D7DH32H3YH";
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-D7DH32H3YH");
  };

  const handleAccept = () => {
    localStorage.setItem("cookieConsentGiven", "true");
    setShowBanner(false);
    initializeAnalytics(); // Initialize Google Analytics after acceptance
  };

  const handleDecline = () => {
    setShowBanner(false);
    // Do not initialize Google Analytics or other tracking scripts
  };

  if (!showBanner) return null;

  return (
    <div className={styles.banner}>
      <p>
        Denne siden bruker cookies for å forbedre brukeropplevelsen og vårt
        produkt.
      </p>
      <div className={styles.button_div}>
        <button className={styles.acceptButton} onClick={handleAccept}>
          Jeg godtar!
        </button>
        <button className={styles.declineButton} onClick={handleDecline}>
          Jeg godtar ikke
        </button>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
