import React, { useState, useEffect } from "react";
import styles from "./ImageSlider.module.scss";
import img1 from "../../assets/faces.webp";
// import img2 from "../../assets/square.webp";
import img3 from "../../assets/sofa_collision.webp";

function ImageSlider() {
  const [showFirstImage, setShowFirstImage] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstImage((prevShowFirstImage) => !prevShowFirstImage);
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.slider}>
      <div
        className={`${styles.image} ${showFirstImage ? styles.show : ""}`}
        style={{
          backgroundImage: `url(${img1})`,
        }}
      ></div>
      {/* <div
        className={`${styles.image} ${!showFirstImage ? styles.show : ""}`}
        style={{ backgroundImage: `url(${img2})` }}
      ></div> */}
      <div
        className={`${styles.image} ${!showFirstImage ? styles.show : ""}`}
        style={{ backgroundImage: `url(${img3})` }}
      ></div>
    </div>
  );
}

export default ImageSlider;
