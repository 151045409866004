import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./FAQSection.module.scss";
import Accordion from "../Accordion/";

// Language
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function FAQSection() {
  const { language } = useContext(LanguageContext);
  const translations = language === "no" ? no : en;
  const currentFaqs = translations.FAQ.faqs;

  return (
    <section className={styles.FAQ_section}>
      <div className="container">
        <div className="wrap">
          <div className={styles.heading}>
            <div className="vertical-line gradient-vertical"></div>
            <h1>{translations.FAQ.title}</h1>
          </div>
          <div className={styles.content_flex}>
            <div className={styles.left}>
              <h2>{translations.FAQ.title2}</h2>
              <Link className="cta cta-dark" to="/faq">
                {translations.home.button.seeMoreQuestions}
              </Link>
            </div>
            <div className={styles.right}>
              <Accordion faqs={currentFaqs}></Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQSection;
