import React from "react";
import styles from "./HeroSmall.module.scss";

function HeroSmall({ bg, title }) {
  return (
    <div
      className={styles.small_hero}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="overlay"></div>
      <div className={styles.small_hero_content}>
        <h1>{title}</h1>
      </div>
    </div>
  );
}

export default HeroSmall;
