import React, { useContext } from "react";
import { Link } from "react-scroll";
import { Fade } from "react-awesome-reveal";
// Language
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function Hero({ styles, bg, arrow }) {
  const { language } = useContext(LanguageContext);
  const translations = language === "en" ? en.home.hero : no.home.hero;

  return (
    <div className={styles.hero} style={{ backgroundImage: `url(${bg})` }}>
      <div className="overlay"></div>
      <div className={styles.hero_content}>
        <Fade direction="up">
          <h3>{translations[1]}</h3>
        </Fade>
        <Fade delay={100} direction="up">
          <h1>{translations[2]}</h1>
        </Fade>
        <div className="underline gradient"></div>
        <Fade delay={200} direction="up">
          <p>{translations[3]}</p>
          <p className={styles.bold}>{translations[4]}</p>
        </Fade>
        <Fade delay={300} direction="up">
          <Link
            to="findUs"
            href="#findUs"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="cta"
          >
            {language === "en" ? en.home.button.findUs : no.home.button.findUs}
          </Link>
        </Fade>
        <Fade delay={400} direction="up">
          <div className={styles.arrowDiv}>
            <img src={arrow} alt="arrow" />
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default Hero;
