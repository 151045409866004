import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./pages/Book";
import Franchise from "./pages/Franchise";
import ConceptPage from "./pages/Concept";
import ScrollToTop from "./components/ScrollToTop";
import FAQ from "./pages/FAQ";
import Gallery from "./pages/Gallery";
import { LanguageProvider } from "./context/LanguageContext";
import CookieBanner from "./components/CookieBanner";

function App() {
  return (
    <LanguageProvider>
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          <Navbar />
          <div className="Routes">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/concept" element={<ConceptPage />} />
              <Route path="/franchise" element={<Franchise />} />
              <Route path="/FAQ" element={<FAQ />} />
              <Route path="/Gallery" element={<Gallery />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </HelmetProvider>
      <CookieBanner />
    </LanguageProvider>
  );
}

export default App;
