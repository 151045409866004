import React from "react";
import styles from "./Accordion.module.scss";

const SquareRectangleAccordion = () => {
  return (
    <div className={styles.accordion}>
      {/* Square Sizes and Prices */}
      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Square</h4>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>40 x 40cm</p>
          <p className={styles.price}>3999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>50 x 50cm</p>
          <p className={styles.price}>4999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>60 x 60cm</p>
          <p className={styles.price}>6499 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>80 x 80cm</p>
          <p className={styles.price}>11999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>100 x 100cm</p>
          <p className={styles.price}>16999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>120 x 120cm</p>
          <p className={styles.price}>19999 NOK</p>
        </div>
      </div>

      {/* Rectangle Sizes and Prices */}
      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Rectangle</h4>
        <p className="mb-1">Rectangle is recommended for 5+ irises</p>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>40 x 60cm</p>
          <p className={styles.price}>6999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>60 x 90cm</p>
          <p className={styles.price}>9999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>80 x 120cm</p>
          <p className={styles.price}>14999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>100 x 150cm</p>
          <p className={styles.price}>24999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>120 x 180cm</p>
          <p className={styles.price}>36999 NOK</p>
        </div>
      </div>

      {/* Iris Prices */}
      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Iris Prices</h4>
        <p className="mb-1">
          Price for iris amount is added to the price of the image size.
        </p>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>1 iris</p>
          <p className={styles.price}>0 additional cost</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>2 irises</p>
          <p className={styles.price}>+300 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>3 irises</p>
          <p className={styles.price}>+600 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>4 irises</p>
          <p className={styles.price}>+800 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>5 irises</p>
          <p className={styles.price}>+1000 NOK</p>
        </div>
      </div>
      <div>
        <p className="mb-1">
          Contact us on mail or in the store if you want products with more than
          5 irises.
        </p>
      </div>
    </div>
  );
};

export default SquareRectangleAccordion;
