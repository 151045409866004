import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./FindUs.module.scss";
import { Fade } from "react-awesome-reveal";
// Language
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function FindUs() {
  const { language } = useContext(LanguageContext);
  const translations = language === "en" ? en.home.findUs : no.home.findUs;
  const buttonTranslations =
    language === "en" ? en.home.button : no.home.button;

  const place = "Steinkjellergaten+2+5003+Bergen";

  return (
    <section className={styles.findUsSection} id="findUs">
      <div className="container">
        <div className="wrap">
          <div className={styles.heading}>
            <div className="vertical-line gradient-vertical"></div>
            <h1>{translations.title}</h1>
          </div>
          <div className={styles.content_flex}>
            <Fade direction="up" triggerOnce>
              <div className={styles.card}>
                <h4>Bergen</h4>
                <p>Steinkjellergaten 2, 5003 Bergen</p>
                <div className={styles.open}>
                  {/* <p>{translations.monday}</p>
                  <p>{translations.weekdays}</p>
                  <p>{translations.thursday}</p>
                  <p>{translations.friday}</p>
                  <p>{translations.saturday}</p>
                  <p>{translations.sunday}</p> */}
                  <p>CLOSED UNTIL SEPTEMBER</p>
                </div>
                <div className={styles.button_flex}>
                  <button
                    className="cta cta-dark"
                    onClick={() =>
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${place}`
                      )
                    }
                  >
                    {buttonTranslations.openMap}
                  </button>
                  <Link
                    to={"https://instantiris.onlinebooq.net/"}
                    className="cta gradient"
                  >
                    {buttonTranslations.book}
                  </Link>
                </div>
              </div>
            </Fade>
            {/* <Fade direction="up" delay={200} triggerOnce>
              <div className={styles.card_two}>
                <h4>Vi utvider</h4>
                <p>
                  Har du en grunder i deg og vil bli med i en spennende
                  franchise i vekst?{" "}
                </p>
                <p>Vi ønsker å snakke med deg!</p>
                <button className="cta cta-dark">Les mer!</button>
              </div>
            </Fade> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FindUs;
