import React, { useContext } from "react";
import styles from "./Book.module.scss";
import bg from "../../assets/bgblue.webp";
import Form from "../../components/ContactForm";
import HeroSmall from "../../components/HeroSmall";
import SEO from "../../utils/SEO";

// Import Language Context
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function Book() {
  const { language } = useContext(LanguageContext);
  const text = language === "no" ? no : en;

  console.log(language);
  console.log(text);

  return (
    <>
      <SEO
        title="Kontakt Oss"
        description="Kontakt oss i Instant Iris og få straks svar på dine spørsmål."
      />
      <main>
        <HeroSmall bg={bg} title={text.bookAppointment.title} />
        <section className={styles.book_section}>
          <div className="container">
            <div className="wrap">
              <div className={styles.platform}>
                <div className={styles.content_flex}>
                  <div className={styles.left}>
                    <h4>{text.bookAppointment.dropIn}</h4>
                    {/* <p>{text.bookAppointment.description}</p> */}
                    <p>{text.bookAppointment.tellUs}</p>
                    <div className={styles.opening_hours}>
                      <h4>{text.bookAppointment.openingHours.title}</h4>
                      <p>{text.bookAppointment.openingHours.monday}</p>
                      <p>{text.bookAppointment.openingHours.weekdays}</p>
                      <p>{text.bookAppointment.openingHours.thursday}</p>
                      <p>{text.bookAppointment.openingHours.friday}</p>
                      <p>{text.bookAppointment.openingHours.saturday}</p>
                      <p>{text.bookAppointment.openingHours.sunday}</p>
                    </div>
                    <div>
                      <p>{text.bookAppointment.location.title}</p>
                      <p>{text.bookAppointment.location.address}</p>
                    </div>
                  </div>
                  <div className={styles.right}>
                    <h4>{text.bookAppointment.form.book}</h4>
                    <Form />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Book;
