import React, { useContext } from "react";

// Images
import bg from "../../assets/bgblue.webp";
import wall2 from "../../assets/gallery/FRAME.webp";
import wall3 from "../../assets/gallery/JØLLEHYLLE.webp";
import wall1 from "../../assets/gallery/3square.webp";
// solo
import scatter from "../../assets/gallery/SCATTER_KAREN.webp";
import scatter2 from "../../assets/gallery/SCATTER.webp";
import halo from "../../assets/gallery/HALO.webp";
import clean from "../../assets/gallery/SOLO_CLEAN.jpg";
// duo
import cosmic from "../../assets/gallery/COSMIC.webp";
import infinity from "../../assets/gallery/INFINITY1.webp";
import cosmic2 from "../../assets/gallery/COSMIC2.webp";
import flock from "../../assets/gallery/FLOCK.webp";

import HeroSmall from "../../components/HeroSmall";
import SEO from "../../utils/SEO";
import styles from "./Gallery.module.scss";

import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function Gallery() {
  const { language } = useContext(LanguageContext);
  const text = language === "no" ? no : en;
  return (
    <>
      <SEO
        title="Instant Iris Gallery"
        description="Instant Iris i Bergen - Spesialister på fotografering av iris. Se noen av våre bilder her i vårt digitale galleri"
      />
      <main>
        <HeroSmall bg={bg} title={text.Gallery.title} />
        <section className={styles.gallery_section_walls}>
          <div className="container">
            <div className="wrap">
              <div className={styles.flex_parent}>
                <div className={styles.flex_child}>
                  <div className={styles.header_div}>
                    <h2>WALLS</h2>
                  </div>
                  <div
                    className={styles.background_div}
                    style={{ backgroundImage: `url(${wall2})` }}
                  ></div>
                </div>
                <div className={styles.flex_child}>
                  <div
                    className={styles.background_div}
                    style={{ backgroundImage: `url(${wall1})` }}
                  ></div>
                  <div
                    className={styles.background_div}
                    style={{ backgroundImage: `url(${wall3})` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.gallery_section_solo}>
          <div className="container">
            <div className="wrap">
              <div className={styles.flex_parent_2}>
                <div className={styles.flex_child}>
                  <div className={styles.flex_parent}>
                    <div
                      className={styles.background_div}
                      style={{ backgroundImage: `url(${clean})` }}
                    ></div>
                    <div
                      className={styles.background_div}
                      style={{ backgroundImage: `url(${scatter2})` }}
                    ></div>
                  </div>
                  <div
                    className={styles.background_div}
                    style={{ backgroundImage: `url(${halo})` }}
                  ></div>
                </div>
                <div className={styles.flex_child}>
                  <div className={styles.header_div}>
                    <h2>SOLO EFFECTS</h2>
                  </div>
                  <div
                    className={styles.background_div}
                    style={{ backgroundImage: `url(${scatter})` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.gallery_section_solo}>
          <div className="container">
            <div className="wrap">
              <div className={styles.flex_parent}>
                <div className={styles.flex_child}>
                  <div className={styles.header_div}>
                    <h2>DUO EFFECTS</h2>
                  </div>
                  <div
                    className={styles.background_div}
                    style={{ backgroundImage: `url(${cosmic2})` }}
                  ></div>
                </div>
                <div className={styles.flex_child}>
                  <div className={styles.flex_parent}>
                    <div
                      className={styles.background_div}
                      style={{ backgroundImage: `url(${infinity})` }}
                    ></div>
                    <div
                      className={styles.background_div}
                      style={{ backgroundImage: `url(${cosmic})` }}
                    ></div>
                  </div>
                  <div
                    className={styles.background_div}
                    style={{ backgroundImage: `url(${flock})` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Gallery;
