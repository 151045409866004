import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import styles from "./Accordion.module.scss";

function Accordion({ faqs }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index);
    if (index === activeIndex) {
      setActiveIndex(null);
    }
  };

  const renderedFaqs = faqs.map((faq, index) => {
    const active = index === activeIndex ? "active" : "";

    return (
      <div
        key={faq.question}
        className={`${styles.accordion} ${active}`}
        onClick={() => handleAccordionClick(index)}
        onKeyUp={() => handleAccordionClick(index)}
        tabIndex={0}
      >
        <div className="accordion_heading">
          <div className={styles.q_wrap}>
            <h5>{faq.question}</h5>
            <span className="icon">{index !== activeIndex ? "+" : "-"}</span>
          </div>
        </div>
        <div
          className={`${styles.answer_div} ${
            index === activeIndex ? styles.show : ""
          }`}
        >
          <p>{faq.answer}</p>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.accordion_wrap}>
      <Fade direction="right" triggerOnce>
        {renderedFaqs}
      </Fade>
    </div>
  );
}

export default Accordion;
