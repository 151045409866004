import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/irislogo.png";
import styles from "./Navbar.module.scss";
import Hamburger from "../Hamburger";
import { LanguageContext } from "../../context/LanguageContext";

function Navbar() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [active, setActive] = useState(false);

  const toggleLang = () => {
    if (language === "no") {
      setLanguage("en");
    } else {
      setLanguage("no");
    }
  };

  const handleMenuClick = () => {
    const menu = document.querySelector(`.${styles.nav_ul}`);
    menu.classList.toggle(`${styles.active}`);
    const isMenuActive = menu.classList.contains(`${styles.active}`);

    if (window.innerWidth < 768) {
      if (isMenuActive) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }

    setActive(isMenuActive);
  };

  return (
    <header>
      <div className="container">
        <div className="wrap">
          <div className={styles.header_flex}>
            <div className={styles.logo}>
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <nav>
              <ul className={styles.nav_ul}>
                <li>
                  <NavLink
                    to="/"
                    exact="true"
                    className={({ isActive }) =>
                      isActive ? styles["active-link"] : ""
                    }
                    onClick={handleMenuClick}
                  >
                    {language === "no" ? "Hjem" : "Home"}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/concept"
                    exact="true"
                    className={({ isActive }) =>
                      isActive ? styles["active-link"] : ""
                    }
                    onClick={handleMenuClick}
                  >
                    {language === "no"
                      ? "Konsept & Produkter"
                      : "Concept & Products"}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    exact="true"
                    className={({ isActive }) =>
                      isActive ? styles["active-link"] : ""
                    }
                    onClick={handleMenuClick}
                  >
                    {language === "no" ? "Kontakt Oss" : "Contact Us"}
                  </NavLink>
                </li>
                <li>
                  <a href="https://instantiris.onlinebooq.net">
                    {language === "no" ? "Bestill Time" : "Book"}
                  </a>
                </li>
                {/* <li>
                  <NavLink
                    to="/franchise"
                    exact="true"
                    className={({ isActive }) =>
                      isActive ? styles["active-link"] : ""
                    }
                    onClick={handleMenuClick}
                  >
                    {language === "no" ? "Franchise" : "Franchise"}
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to="/FAQ"
                    exact="true"
                    className={({ isActive }) =>
                      isActive ? styles["active-link"] : ""
                    }
                    onClick={handleMenuClick}
                  >
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Gallery"
                    exact="true"
                    className={({ isActive }) =>
                      isActive ? styles["active-link"] : ""
                    }
                    onClick={handleMenuClick}
                  >
                    {language === "no" ? "Galleri" : "Gallery"}
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className={styles.header_right}>
              <div className={styles.languageButton}>
                <button onClick={toggleLang}>
                  {language === "no" ? "NO" : "EN"}
                </button>
              </div>
              <div className={styles.hamburgerDiv}>
                <button aria-label="Menu" onClick={handleMenuClick}>
                  <Hamburger active={active} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
