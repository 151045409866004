import React from "react";
import styles from "./Review.module.scss";

function Review() {
  return (
    <section>
      <div className="container">
        <div className="wrap">
          <div className={styles.reviews_parent}>
            <div className={styles.reviews_child}>
              <div className={styles.review_stars}>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
              </div>
              <p>
                «For our anniversary this year I secretly made an appointment at
                Instant Iris.. He made the experience a blast and made our eyes
                look incredible. Im so happy with this, its going to look insane
                in our future living room»
              </p>
            </div>
            <div className={styles.reviews_child}>
              <div className={styles.review_stars}>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
              </div>
              <p>
                «Popped in to photograph my eye, was very excited to see how it
                went. Very surprised at how fast it was and there was no
                discomfort either! Done in an instant.»
              </p>
            </div>
            <div className={styles.reviews_child}>
              <div className={styles.review_stars}>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
                <span className={styles.star}>&#9733;</span>
              </div>
              <p>
                «Cool picture, and super service! Will be back 🤩 Fun to see all
                the details so well! It was a nice anniversary gift for me and
                my partner. Thank you again!»
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Review;
