import React, { useContext } from "react";
import styles from "./ConceptSection.module.scss";
import ImageSlider from "../ImageSlider";
import { Link } from "react-router-dom";
import ConceptComponent from "../ConceptComponent";

// Language
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function ConceptSection() {
  const { language } = useContext(LanguageContext);

  return (
    <section className={styles.whatWeDoSection}>
      <div className="container">
        <div className="wrap">
          {/* <div className={styles.heading}>
            <div className="vertical-line gradient-vertical"></div>
            <h1>Konsept</h1>
          </div> */}
          <ConceptComponent styles={styles} ImageSlider={ImageSlider} />
          <div className={styles.button_div}>
            <Link to="/concept" className="cta cta-dark">
              {language === "no"
                ? no.home.button.moreAboutConcept
                : en.home.button.moreAboutConcept}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConceptSection;
