import React, { useContext } from "react";
import HeroSmall from "../../components/HeroSmall";
import bg from "../../assets/bgblue.webp";
import ImageSlider from "../../components/ImageSlider";
import styles from "./Concept.module.scss";
import { Fade } from "react-awesome-reveal";

// Product Images
import digitalFile from "../../assets/digital-file1.webp";
import square from "../../assets/square.webp";
import line from "../../assets/lineweb.webp";
import circle from "../../assets/circle.webp";
import print from "../../assets/shelf.webp";

import camera from "../../assets/concept-icons/camera-icon.png";
import edit from "../../assets/concept-icons/edit-icon.png";
import printer from "../../assets/concept-icons/printer-icon.png";
import frame from "../../assets/concept-icons/frame-icon.png";
import SEO from "../../utils/SEO";
import ProductCard from "../../components/ProductCard";

// Import accordions
import SquareAccordion from "../../components/ProductAccordion/SquareAccordion";
// import BlockAccordion from "../../components/ProductAccordion/BlockAccordion";
import CircleAccordion from "../../components/ProductAccordion/CircleAccordion";
import PaperAccordion from "../../components/ProductAccordion/PaperAccordion";
import LineAccordion from "../../components/ProductAccordion/LineAccordion";
import DigitalAccordion from "../../components/ProductAccordion/DigitalAccordion";
import AluSquareAccordion from "../../components/ProductAccordion/AluSquareAccordion.jsx";
import AluLineAccordion from "../../components/ProductAccordion/AluLineAccordion.jsx";
import AluCircleAccordion from "../../components/ProductAccordion/AluCircleAccordion.jsx";

// Language
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function ConceptPage() {
  const { language } = useContext(LanguageContext);
  const currentLang = language === "no" ? no.concept : en.concept;

  return (
    <>
      <SEO title={currentLang.title} description={currentLang.photo} />
      <main className={styles.main}>
        <HeroSmall bg={bg} title={currentLang.title} />
        <section className={styles.concept_section}>
          <div className="container">
            <div className="wrap">
              <div className={styles.content_flex}>
                <Fade direction="right" triggerOnce className={styles.fade}>
                  <div className={styles.card}>
                    <div className={styles.card_img_div}>
                      <img src={camera} alt="icon of camera" />
                    </div>
                    <p>{currentLang.photo}</p>
                  </div>
                </Fade>
                <Fade
                  delay={200}
                  direction="right"
                  triggerOnce
                  className={styles.fade}
                >
                  <div className={styles.card}>
                    <div className={styles.card_img_div}>
                      <img src={edit} alt="icon of a pen" />
                    </div>
                    <p>{currentLang.edit}</p>
                  </div>
                </Fade>
                <Fade
                  delay={400}
                  direction="right"
                  triggerOnce
                  className={styles.fade}
                >
                  <div className={styles.card}>
                    <div className={styles.card_img_div}>
                      <img src={printer} alt="icon of a printer" />
                    </div>
                    <p>{currentLang.print}</p>
                  </div>
                </Fade>
                <Fade
                  delay={500}
                  direction="right"
                  triggerOnce
                  className={styles.fade}
                >
                  <div className={styles.card}>
                    <div className={styles.card_img_div}>
                      <img src={frame} alt="icon of a heart" />
                    </div>
                    <p>{currentLang.wall}</p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.gif_section}>
          <div className="container">
            <div className="wrap">
              <div className={styles.content_flex}>
                <div className={styles.left}>
                  <ImageSlider />
                </div>
                <div className={styles.right}>
                  <div className={styles.card}>
                    <h4>{currentLang.title1}</h4>
                    <p>{currentLang.text1}</p>
                  </div>
                  <div className={styles.card}>
                    <h4>{currentLang.title2}</h4>
                    <p>{currentLang.text2}</p>
                  </div>
                  <div className={styles.card}>
                    <h4>{currentLang.title3}</h4>
                    <p>{currentLang.text3}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.concept_section}>
          <div className="container">
            <div className="wrap">
              <div className={styles.heading_div}>
                <div className="vertical-line gradient-vertical"></div>
                <h1 className="main-dark">Produkter</h1>
              </div>
              <ProductCard
                image={digitalFile}
                title="Digital Fil - Inkludert ved alle kjøp"
                description="Alle produkter kommer med denne digitale filen inkludert gratis. Få ditt unike bilde tilsendt på e-post som en digital fil. Priser under er ved kjøp av kun digital fil. Bruk det som bakgrunnsbilde på mobil, klokke eller som profilbilde på sosiale medier. Mulighetene er mange!"
                attribute={["Velg blant mange gratis effekter"]}
              >
                <DigitalAccordion />
              </ProductCard>
              <ProductCard
                image={print}
                title="Profesjonelt Fotopapir"
                description="På bare noen minutter blir bildene dine skrevet ut på glanset fotopapir av høy kvalitet. Fullfør utseendet med våre profesjonelle innrammingsalternativer i butikken. Rask, elegant og laget for å vare."
                attribute={["Velg blant mange gratis effekter"]}
              >
                <PaperAccordion />
              </ProductCard>
              <ProductCard
                image={square}
                title="Akryl 5mm - Kvadrat og Rektangel"
                description="Utforsk våre 5mm hardbelagte akryltrykk, der moderne design møter uovertruffen holdbarhet. Forsterk kunsten din med vår glansfulle akryloverflate, spesiallaget for å tilføre et ekstra lag med dybde til bildene dine. I tillegg, med vår HC hardbelegg, kan du enkelt tørke av trykket med en klut, alt uten å kompromittere dets integritet. Opplev kunst i en ny dimensjon."
                attribute={[
                  "Water resistant",
                  "UV resistant",
                  "Scratch resistant",
                  "Velg blant mange gratis effekter",
                ]}
              >
                <SquareAccordion />
              </ProductCard>
              {/* <ProductCard
                image={bg}
                title="Akryl Blokk"
                description="Oppdag elegansen i våre frittstående akrylblokker. Disse blokkene er laget av tykk akryl, noe som gjør at de kan stå uavhengig på hyller eller skrivebord. Dybden i materialet forsterker bildet og skaper en subtil 3D-effekt. Ideell for både personlige og profesjonelle omgivelser, disse blokkene tilbyr en moderne måte å vise frem dine favorittbilder på."
                attribute={[
                  "Water resistant",
                  "UV resistant",
                  "Scratch resistant",
                  "Velg blant mange gratis effekter",
                ]}
              >
                <BlockAccordion />
              </ProductCard> */}
              <ProductCard
                image={line}
                title="Akryl Line"
                description="Møt våre linjeformede akrylmalerier, designet for å fremheve flere øyne på rad. Disse kunstverkene er laget av det samme høykvalitets akrylmaterialet som våre andre akrylprodukter, og gir dermed den samme uovertrufne holdbarheten og glansen. Opplev kunst i en ny, lineær dimensjon."
                attribute={[
                  "Water resistant",
                  "UV resistant",
                  "Scratch resistant",
                  "Velg blant mange gratis effekter",
                ]}
              >
                <LineAccordion />
              </ProductCard>
              <ProductCard
                image={circle}
                title="Akryl Sirkel"
                description="Utforsk den unike sjarmen av våre sirkulære akrylmalerier, formet i likhet med en iris. Disse kunstverkene er laget av det samme høykvalitets akrylmaterialet som våre andre akrylprodukter, og gir dermed den samme uovertrufne holdbarheten og glansen. Opplev kunst i en ny, sirkulær dimensjon som speiler naturens egen perfeksjon."
                attribute={[
                  "Water resistant",
                  "UV resistant",
                  "Scratch resistant",
                  "Velg blant mange gratis effekter",
                ]}
              >
                <CircleAccordion />
              </ProductCard>
              <ProductCard
                image={square}
                title="Aluminium 3mm - Kvadrat og Rektangel"
                description="Oppdag den moderne elegansen til våre 3mm aluminiumstrykk. Med aluminium overflate og svart PVC kjerne er de lette og enkle å henge opp, og kan henge trygt både inne og ute. Den matte overflaten gir et moderne utseende, og gjør at bildet ditt kan skinne gjennom. Opplev kunst i en ny dimensjon."
                attribute={[
                  "Water resistant",
                  "UV resistant",
                  "Velg blant mange gratis effekter",
                ]}
              >
                <AluSquareAccordion />
              </ProductCard>
              <ProductCard
                image={line}
                title="Aluminium Line"
                description="Møt våre linjeformede 3mm aluminiumstrykk, designet for å fremheve flere øyne på rad. Disse kunstverkene er laget av det samme høykvalitets aluminiumsmaterialet som våre andre aluminiumprodukter, og gir dermed den samme uovertrufne holdbarheten med en stilig matt overflate. Opplev kunst i en ny, lineær dimensjon."
                attribute={[
                  "Water resistant",
                  "UV resistant",
                  "Velg blant mange gratis effekter",
                ]}
              >
                <AluLineAccordion />
              </ProductCard>
              <ProductCard
                image={circle}
                title="Aluminium Sirkel"
                description="Utforsk våre sirkulære aluminiumstrykk, formet i likhet med en iris. Disse kunstverkene er laget av det samme høykvalitets aluminiumsmaterialet som våre andre aluminiumsprodukter, og gir dermed den samme uovertrufne holdbarheten med en stilig matt overflate. Opplev kunst i en ny, sirkulær dimensjon som speiler naturens egen perfeksjon."
                attribute={[
                  "Water resistant",
                  "UV resistant",
                  "Velg blant mange gratis effekter",
                ]}
              >
                <AluCircleAccordion />
              </ProductCard>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ConceptPage;
