import React, { useContext } from "react";
import styles from "./ContactForm.module.scss";

// Language
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

const ContactForm = () => {
  const { language } = useContext(LanguageContext);
  const currentLang =
    language === "no" ? no.bookAppointment : en.bookAppointment;

  const validateForm = (e) => {
    // You can add more custom validation here if needed
  };

  return (
    <form
      className={styles.form}
      onSubmit={validateForm}
      name="contact"
      data-netlify="true"
      method="POST"
    >
      <input type="hidden" name="form-name" value="contact" />

      <label>
        {currentLang.form.name}
        <input
          type="text"
          name="name"
          placeholder="Ine Iris"
          required
          pattern="^[a-zA-Z\s]{3,40}$"
          title="Name should be between 3 and 40 characters and contain only letters and spaces."
        />
      </label>

      <label>
        {currentLang.form.email}
        <input
          type="email"
          name="email"
          placeholder="customer@eksempel.no"
          required
          pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
          title="Please enter a valid email address."
        />
      </label>

      <label>
        {currentLang.form.message}
        <textarea
          name="message"
          placeholder={currentLang.form.exampleMessage}
          required
          minlength="10"
          maxlength="500"
        />
      </label>
      <input type="text" name="bot-field" hidden />
      <button className="cta gradient" type="submit">
        {currentLang.form.button}
      </button>
    </form>
  );
};

export default ContactForm;
