// CircleAccordion.js
import React from "react";
import styles from "./Accordion.module.scss";

const CircleAccordion = () => {
  return (
    <div className={styles.accordion}>
      {/* Circle Sizes and Prices */}
      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Circle</h4>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>40 x 40cm</p>
          <p className={styles.price}>3999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>50 x 50cm</p>
          <p className={styles.price}>4999 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>60 x 60cm</p>
          <p className={styles.price}>6499 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>80 x 80cm</p>
          <p className={styles.price}>12399 NOK</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>100 x 100cm</p>
          <p className={styles.price}>17999 NOK</p>
        </div>
      </div>

      {/* Iris Prices */}
      <div className={styles.shape_wrap}>
        <h4 className="mb-1">Iris Prices</h4>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>1 iris</p>
          <p className={styles.price}>0 additional cost</p>
        </div>
        <div className="flex gap-1 sm-justify-between">
          <p className={styles.value}>2 irises</p>
          <p className={styles.price}>+300 NOK</p>
        </div>
      </div>
    </div>
  );
};

export default CircleAccordion;
