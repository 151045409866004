import React, { useContext } from "react";
import styles from "./ConceptComponent.module.scss";
import { Fade } from "react-awesome-reveal";
// Language
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function ConceptComponent({ ImageSlider }) {
  const { language } = useContext(LanguageContext);
  const translations =
    language === "en" ? en.home.conceptText : no.home.conceptText;

  return (
    <div className={styles.content_flex}>
      <div className={styles.left}>
        <Fade triggerOnce>
          <ImageSlider />
        </Fade>
      </div>
      <div className={styles.right}>
        <Fade direction="right" triggerOnce>
          <div className={styles.card}>
            <h4>{translations[1].title}</h4>
            <p>{translations[1].text}</p>
          </div>
        </Fade>
        <Fade delay={200} direction="right" triggerOnce>
          <div className={styles.card}>
            <h4>{translations[2].title}</h4>
            <p>{translations[2].text}</p>
          </div>
        </Fade>
        <Fade delay={400} direction="right" triggerOnce>
          <div className={styles.card}>
            <h4>{translations[3].title}</h4>
            <p>{translations[3].text}</p>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default ConceptComponent;
