import React, { useRef, useContext } from "react";
import HeroSmall from "../../components/HeroSmall";
import bg from "../../assets/bgblue.webp";
import styles from "./FAQ.module.scss";
import Accordion from "../../components/Accordion";
import SEO from "../../utils/SEO";

// Language
import { LanguageContext } from "../../context/LanguageContext";
import no from "../../context/no";
import en from "../../context/en";

function FAQ() {
  const { language } = useContext(LanguageContext);
  const text = language === "no" ? no : en;

  const forberedelserRef = useRef(null);
  const paTimenRef = useRef(null);
  const etterTimenRef = useRef(null);

  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <SEO
        title="FAQ"
        description="Ofte stilte spørsmål. Her finner du svar på det du lurer på!"
      />
      <main>
        <HeroSmall bg={bg} title="FAQ" />
        <section className={styles.FAQ_page_section}>
          <div className="container">
            <div className="wrap">
              <div className={styles.content_flex}>
                <div className={styles.left}>
                  <div className={styles.select_wrap}>
                    <button
                      onClick={() => scrollTo(forberedelserRef)}
                      className="cta-dark"
                    >
                      {text.FAQs.button1}
                    </button>
                    <button
                      onClick={() => scrollTo(paTimenRef)}
                      className="cta-dark"
                    >
                      {text.FAQs.button2}
                    </button>
                    <button
                      onClick={() => scrollTo(etterTimenRef)}
                      className="cta-dark"
                    >
                      {text.FAQs.button3}
                    </button>
                  </div>
                </div>
                <div className={styles.right}>
                  <div>
                    <h2 ref={forberedelserRef}>{text.FAQs.button1}</h2>
                    <Accordion faqs={text.FAQs.section_1} />
                  </div>
                  <div>
                    <h2 ref={paTimenRef}>{text.FAQs.button2}</h2>
                    <Accordion faqs={text.FAQs.section_2} />
                  </div>
                  <div>
                    <h2 ref={etterTimenRef}>{text.FAQs.button3}</h2>
                    <Accordion faqs={text.FAQs.section_3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default FAQ;
